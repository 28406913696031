import PropTypes from "prop-types"
import React from "react"

// Utilities
import kebabCase from "lodash/kebabCase"

// Components
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import Layout from "../components/Layout"
import SEO from "../components/Seo"

interface IProp {
    location?: any
    data: {
        allMdx: {
            group: [
                {
                    fieldValue: string
                    totalCount: number,
                },
            ],
        }
        site: {
            siteMetadata: {
                title: string,
            },
        },
    }
}

class CategoriesPage extends React.Component<IProp> {
    //   data: {
    //     allMarkdownRemark: { group },
    //     site: {
    //       siteMetadata: { title },
    //     },
    //   }
    public render() {
        const { data } = this.props
        const title = data.site.siteMetadata.title
        const { group } = data.allMdx

        // console.log(group)
        return (
            <Layout location={this.props.location} title={title}>
                <Helmet title={title} />
                <SEO title={title} />
                <div>
                    <h1>categories</h1>
                    <ul>
                        {group.map((category: any) => (
                            <li key={category.fieldValue}>
                                <Link
                                    to={`/categories/${kebabCase(
                                        category.fieldValue,
                                    )}/`}
                                >
                                    {category.fieldValue} ({category.totalCount}
                                    )
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
            </Layout>
        )
    }
}

export default CategoriesPage

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        allMdx(
            limit: 2000
            filter: { frontmatter: { published: { ne: false } } }
        ) {
            group(field: frontmatter___categories) {
                fieldValue
                totalCount
            }
        }
    }
`
